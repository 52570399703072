<template>
  <div class="enquiry-dashboard">
    <v-row>
      <v-col md="6">
        <v-card flat class="py-4">
          <div class="text-h5 font-weight-bold text-uppercase px-5">
            COMPANY STATUS
          </div>
          <v-layout class="px-3 overflow-hidden">
            <v-flex
              v-for="(item, index) in companySummaryData"
              :key="index"
              class="col-4"
            >
              <v-card
                flat
                class="rounded-xl px-4"
                :style="`background: linear-gradient(to left, ${getGradient(
                  item.color
                )});`"
              >
                <v-card-text>
                  <div class="text-center py-4">
                    <v-avatar color="white" size="100"
                      ><v-icon :size="60" :color="item.iconColor">
                        {{ item.icon }}
                      </v-icon></v-avatar
                    >
                  </div>
                  <div class="d-flex align-center">
                    <div :class="`text-h3 text-white font-weight-bold  mt-1`">
                      {{ item.value }}
                    </div>
                    <v-spacer />
                    <div class="text-h5 text-white fw-500">
                      {{ item.title }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card flat class="py-3 px-3 h-100">
          <v-card-text>
            <div class="d-flex align-center pb-3">
              <div class="text-h5 font-weight-bold text-uppercase">
                Costing Status
              </div>
              <v-spacer />
            </div>
            <div class="d-flex align-center justify-space-between">
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">All Costing</div>
                  <div class="text-h3 font-weight-bold cyan--text mt-1">37</div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Pending</div>
                  <div class="text-h3 font-weight-bold orange--text mt-1">
                    12
                  </div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Approved</div>
                  <div class="text-h3 font-weight-bold green--text mt-1">
                    12
                  </div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Draft</div>
                  <div class="text-h3 font-weight-bold indigo--text mt-1">
                    12
                  </div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Rejected</div>
                  <div class="text-h3 font-weight-bold red--text mt-1">1</div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xl="5" lg="6">
        <v-card flat class="px-3" height="100%">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase py-4">
                Opportunity Status
              </div>
            </div>
            <div id="chart">
              <apexchart
                type="donut"
                width="520"
                :options="oppStatuschartOptions"
                :series="oppStatusseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
        <v-card flat class="py-4 h-100" v-if="false">
          <div class="text-h5 font-weight-bold text-uppercase px-4">
            Opportunity
          </div>
          <v-layout class="px-3 overflow-hidden d-flex flex-wrap">
            <v-flex
              v-for="(item, index) in opportunitySummaryData"
              :key="index"
              class="col-4"
            >
              <v-card
                class="px-2 rounded-xl"
                outlined
                :color="`${item.color} lighten-4`"
              >
                <v-card-text>
                  <!-- <v-icon :size="45" :color="item.iconColor">
                    {{ item.icon }}
                  </v-icon> -->
                  <v-img
                    width="55"
                    src="/media/client-logos/oppotunity.png"
                  ></v-img>
                  <div class="d-flex align-center">
                    <div :class="`text-h3 font-weight-bold my-2`">
                      {{ item.value }}
                    </div>
                    <v-spacer />
                  </div>
                  <div :class="`fw-500 text-h6 ${item.textColor}`">
                    {{ item.title }}
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col xl="7" lg="6">
        <v-card flat class="py-4 h-100">
          <div class="text-h5 font-weight-bold text-uppercase px-4">
            Quotation Status
          </div>
          <v-layout class="px-3 overflow-hidden d-flex flex-wrap">
            <v-flex
              v-for="(item, index) in quotationSummaryData"
              :key="index"
              :class="index == 6 ? 'col-12' : 'col-4'"
            >
              <v-card outlined :color="`${item.color} lighten-5`">
                <v-card-text>
                  <div class="text-h6 fw-500">{{ item.title }}</div>
                  <div class="d-flex align-center">
                    <div
                      :class="`text-h3 font-weight-bold ${item.textColor} mt-1`"
                    >
                      {{ item.value }}
                    </div>
                    <v-spacer />
                    <span class="svg-icon svg-icon-4x svg-icon-warning">
                      <!--begin::Svg Icon-->
                      <inline-svg :src="item.icon" />
                      <!--end::Svg Icon-->
                    </span>
                    <!-- <v-icon :size="40" :color="item.iconColor">
                      {{ item.icon }}
                    </v-icon> -->
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card flat class="py-4">
          <div class="text-h5 font-weight-bold text-uppercase px-7 pb-3">
            Project Status
          </div>
          <v-layout class="px-3 overflow-hidden">
            <v-flex
              v-for="(item, index) in projectSummaryData"
              :key="index"
              class="mx-4"
            >
              <v-card
                outlined
                :color="`${item.color} lighten-5`"
                :style="`border-color: ${getBgColor(item.color)}!important`"
              >
                <v-card-text>
                  <div class="text-h6 fw-500">{{ item.title }}</div>
                  <div class="d-flex align-center">
                    <div
                      :class="`text-h3 font-weight-bold ${item.textColor} mt-1`"
                    >
                      {{ item.value }}
                    </div>
                    <v-spacer />
                    <!-- <v-icon :size="40" :color="item.iconColor">
                      {{ item.icon }}
                    </v-icon> -->
                    <span class="svg-icon svg-icon-4x svg-icon-warning">
                      <inline-svg contain :src="item.icon" />
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>

      <v-col md="12">
        <v-card flat class="px-3">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                Quotation Accepted /Rejected
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="durationList"
                  v-model="isDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
            </div>
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="newClosedJobschartOptions"
                :series="newClosedJobsseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12" v-if="false">
        <v-card
          flat
          class="px-3 py-4"
          style="
            background-image: url(/media/bg/dots-structure-vector.jpg);
            background-size: cover;
            background-position: bottom;
          "
        >
          <v-card-text>
            <div class="d-flex align-center pb-4">
              <div>
                <div class="text-h5 font-weight-bold text-uppercase pb-4">
                  Invoices
                </div>
                <div class="font-size-20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </div>
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="invoicesdurationList"
                  v-model="isInvoicesDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
            </div>
            <div class="invoice">
              <b-progress class="mt-2" height="10rem" :max="max" show-value>
                <b-progress-bar :value="value" variant="cyan"
                  ><span class="text-h4 mb-2"
                    >Draft
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold cyan--text"
                        >2</span
                      ></v-avatar
                    ></span
                  >
                  <strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
                <b-progress-bar :value="value" variant="warning"
                  ><span class="text-h4 mb-2"
                    >Sent:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold warning--text"
                        >2</span
                      ></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
                <b-progress-bar :value="value" variant="warning"
                  ><span class="text-h4 mb-2"
                    >Partially Paid:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold warning--text"
                        >2</span
                      ></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
                <b-progress-bar :value="value" variant="green"
                  ><span class="text-h4 mb-2"
                    >Paid:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold green--text"
                        >2</span
                      ></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >

                <b-progress-bar :value="value" variant="danger"
                  ><span class="text-h4 mb-2"
                    >Cancelled:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold red--text"
                        >2</span
                      ></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
              </b-progress>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12" v-if="false">
        <v-card flat class="px-3">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                New/Collected/Due Amount for 6 months
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="durationList"
                  v-model="isDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
            </div>
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="newClosedJobschartOptions"
                :series="newClosedJobsseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ProjectStatus",
  data() {
    return {
      value: 6000,
      max: 100,
      issueCountLoadingNew: false,
      companySummaryData: [
        {
          title: "Total",
          value: 8,
          color: "#2196F3",
          textColor: "blue--text",
          iconColor: "blue",
          icon: "mdi-domain",
        },
        {
          title: "Active",
          value: 4,
          color: "#4CAF50",
          textColor: "green--text",
          iconColor: "green",
          icon: "mdi-domain",
        },
        {
          title: "In-Active",
          value: 4,
          color: "#F44336",
          textColor: "red--text",
          iconColor: "red",
          icon: "mdi-domain",
        },
      ],
      opportunitySummaryData: [
        {
          title: "All Opportunity",
          value: 16,
          color: "cyan",
          textColor: "cyan--text",
          iconColor: "cyan",
          icon: "mdi-hand-heart-outline",
        },
        {
          title: "Not Started",
          value: 6,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "mdi-hand-heart-outline",
        },
        {
          title: "In-Progress",
          value: 1,
          color: "blue",
          textColor: "blue--text",
          iconColor: "blue",
          icon: "mdi-hand-heart-outline",
        },
        {
          title: "On Hold",
          value: 4,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "mdi-hand-heart-outline",
        },
        {
          title: "Cancelled",
          value: 2,
          color: "red",
          textColor: "red--text",
          iconColor: "red",
          icon: "mdi-hand-heart-outline",
        },
        {
          title: "Finished",
          value: 3,
          color: "green",
          textColor: "green--text",
          iconColor: "green",
          icon: "mdi-hand-heart-outline",
        },
      ],

      quotationSummaryData: [
        {
          title: "All Quotations",
          value: 33,
          color: "cyan",
          textColor: "cyan--text",
          iconColor: "cyan",
          icon: "/media/custom-svg/quotation.svg",
        },
        {
          title: "Draft",
          value: 3,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "/media/custom-svg/draft.svg",
        },
        {
          title: "Accepted",
          value: 6,
          color: "green",
          textColor: "green--text",
          iconColor: "green",
          icon: "/media/custom-svg/accept-approved.svg",
        },
        {
          title: "Unapproved",
          value: 2,
          color: "red",
          textColor: "red--text",
          iconColor: "red",
          icon: "/media/custom-svg/reject-unapproved.svg",
        },
        {
          title: "Approved",
          value: 6,
          color: "blue",
          textColor: "blue--text",
          iconColor: "blue",
          icon: "/media/custom-svg/accept-approved.svg",
        },
        {
          title: "Rejected",
          value: 2,
          color: "red",
          textColor: "red--text",
          iconColor: "red",
          icon: "/media/custom-svg/reject-unapproved.svg",
        },
        {
          title: "Sent",
          value: 14,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "/media/custom-svg/sent.svg",
        },
      ],
      projectSummaryData: [
        {
          title: "All Projects",
          value: 21,
          color: "cyan",
          textColor: "cyan--text",
          iconColor: "cyan",
          icon: "/media/custom-svg/projects.svg",
        },
        {
          title: "Not Started",
          value: 6,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "/media/custom-svg/not_started.svg",
        },
        {
          title: "In-Progress",
          value: 6,
          color: "blue",
          textColor: "blue--text",
          iconColor: "blue",
          icon: "/media/custom-svg/in_progress.svg",
        },
        {
          title: "On Hold",
          value: 1,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "/media/custom-svg/on-hold.svg",
        },
        {
          title: "Cancelled",
          value: 2,
          color: "red",
          textColor: "red--text",
          iconColor: "red",
          icon: "/media/custom-svg/cancelled.svg",
        },
        {
          title: "Finished",
          value: 6,
          color: "green",
          textColor: "green--text",
          iconColor: "green",
          icon: "/media/custom-svg/finished.svg",
        },
      ],
      newClosedJobsseries: [
        {
          name: "Accepted",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
        {
          name: "Rejected",
          data: [12, 11, 14, 18, 17, 13, 13],
        },
      ],
      newClosedJobschartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#4CAF50", "#F44336"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Accepted & Rejected Quotation",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Quotation",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
      oppStatusseries: [6, 1, 4, 2, 3],
      oppStatuschartOptions: {
        chart: {
          width: 425,
          type: "donut",
        },
        /*  plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 400,
          },
        }, */
        labels: [
          "Not Started",
          "In-Progress",
          "Cancelled",
          "On Hold",
          "Finished",
        ],
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        title: {
          text: "Total: 16",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      categoryList: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
        { text: "Overdue", value: "overdue" },
      ],
      isCategory: "open",
      invoicesdurationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
      ],
      isInvoicesDuration: "this_month",
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
      ],
      isDuration: "this_month",
    };
  },
  methods: {
    getBgColor(color) {
      switch (color) {
        case "cyan":
          return "#00bcd4";
        case "green":
          return "#4CAF50";
        case "red":
          return "#F44336";
        case "blue":
          return "#2196F3";
        case "orange":
          return "#ff9800";
        default:
          return "white";
      }
    },
    getGradient(baseColor) {
      // Customize the gradient based on the base color
      if (baseColor === "#2196F3") {
        // Blue
        return "#2196F3, #21CBF3";
      } else if (baseColor === "#4CAF50") {
        // Green
        return "#4CAF50, #8BC34A";
      } else if (baseColor === "#F44336") {
        // Red
        return "#F44336, #E91E63";
      }
      return `${baseColor}, ${baseColor}80`; // Fallback to a single color gradient
    },
    randomValue() {
      this.value = Math.random() * this.max;
    },
  },
};
</script>
<style scoped>
.invoice .progress-bar:first-child {
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 0px) 50%
  );
}
.invoice .progress-bar {
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}
.invoice .progress-bar:last-child {
  clip-path: polygon(
    0% 0%,
    calc(100% + 0px) 0%,
    100% 100%,
    calc(100% - 40px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}
</style>
